import { Component, Input, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationMessage, NotificationService, NotificationTypeEnum } from '@xpo-ltl/data-api';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoSnackBarStatus } from '@xpo-ltl/ngx-ltl-core/snack-bar/models/xpo-snack-bar-status.type';
import { SnackbarStatus } from '../enums/snackbar-status.enum';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
  message: string;
  show: boolean;

  @Input()
  showProgressSpinner = true;

  private snackBarRef: any;

  constructor(
    private notificationService: NotificationService,
    private zone: NgZone,
    private snackbar: XpoSnackBar
  ) {}

  ngOnInit() {
    this.notificationService.getSubscriber().subscribe((message: NotificationMessage) => {
      try {
        this.zone.run(() => {
          if (message.type !== NotificationTypeEnum.ShowSnackbar) {
            this.message = message.text;
            this.show = message.type === NotificationTypeEnum.ShowOverlay;
          } else {
            this.snackBarRef = this.snackbar.open({
              message: message.text,
              status: SnackbarStatus[message.snackbarConfig.status] as XpoSnackBarStatus,
              action:
                message.snackbarConfig != null &&
                message.snackbarConfig.actionHandler != null &&
                message.snackbarConfig.actionHandler.actionLabel() != null
                  ? message.snackbarConfig.actionHandler.actionLabel()
                  : null,
              matConfig: {
                duration: message.snackbarConfig.durationInMillis,
              },
            });

            if (message.snackbarConfig != null && message.snackbarConfig.actionHandler != null) {
              if (message.snackbarConfig.actionHandler.onAction != null) {
                this.snackBarRef.onAction().subscribe(message.snackbarConfig.actionHandler.onAction);
              }
              if (message.snackbarConfig.actionHandler.afterOpened != null) {
                this.snackBarRef.afterOpened().subscribe(message.snackbarConfig.actionHandler.afterOpened);
              }
              if (message.snackbarConfig.actionHandler.afterDismissed != null) {
                this.snackBarRef.afterDismissed().subscribe(message.snackbarConfig.actionHandler.afterDismissed);
              }
            }
          }
        });
      } catch (error) {
        // todo: log
      }
    });
  }
}
