import { Pipe, PipeTransform } from '@angular/core';
import { XPO_FILTER_OPERATORS_MAP, XPO_PARSE_APPLIED_FILTER_TEXT } from './applied-filters.helper';

@Pipe({ name: 'appliedFiltersDisplay' })
export class AppliedFiltersDisplayPipe implements PipeTransform {
  // {[key: string]: any} is the filter type model from ag-grid
  // https://github.com/ag-grid/ag-grid/blob/master/community-modules/core/src/ts/filter/filterManager.ts
  transform(filter: { [key: string]: any }): string {
    // For some annoying and weird reason Ag-grid returns condition1 and condition2 when user enters a
    // second condition but returns the filter data in the first level of the object when user uses only one condition.
    // So it doesn't follow the same object structure so I got to do this, but whatever...
    const cond1Text: string = XPO_PARSE_APPLIED_FILTER_TEXT[filter.filterType](filter.condition1 || filter);
    const cond2Text: string = filter.condition2
      ? XPO_PARSE_APPLIED_FILTER_TEXT[filter.filterType](filter.condition2)
      : '';

    return filter.condition2 ? `${cond1Text} ${XPO_FILTER_OPERATORS_MAP[filter.operator]} ${cond2Text}` : cond1Text;
  }
}
