<div *ngIf="appliedFiltersList.length" class="applied-filters">
  <h4 class="applied-filters-title">
    Applied filters
    <mat-chip xpoBadgeChip color="accent" selected class="applied-filters-titleBadge">{{
      appliedFiltersList.length
    }}</mat-chip>
  </h4>
  <div class="applied-filters-wrapper">
    <mat-chip-list class="applied-filters-chipList">
      <mat-chip *ngFor="let appliedFilterItem of appliedFiltersList" (removed)="removeFilter(appliedFilterItem)" xpoTag>
        <span class="applied-filters-column">{{ appliedFilterItem.columnHeader }}</span>
        <span class="applied-filters-value">{{ appliedFilterItem.filter | appliedFiltersDisplay }}</span>
        <xpo-icon matChipRemove iconName="close"></xpo-icon>
      </mat-chip>
    </mat-chip-list>
    <button class="applied-filters-clearAll" (click)="removeAllFilters()">Clear all</button>
  </div>
</div>
