<div *ngIf="!isPinnedRow() && obiIncentiveNbr && obiIncentiveNbr > 0">
  <button
    mat-icon-button
    class="inline-button-icon"
    (click)="redirect(true)"
    matTooltip="Open {{ obiIncentiveNbr }} incentive details in a new window"
    matTooltipPosition="above"
  >
    <xpo-icon iconName="open-in-new"></xpo-icon>
  </button>
  <a (click)="redirect(false)" xpo-regular-link class="incentive-id">{{ obiIncentiveNbr }}</a>
</div>
