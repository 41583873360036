<div xpoDialog>
  <h1 mat-dialog-title *ngIf="data.action === agreementActionType.EXPIRY">
    Update Expire date
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <h1 mat-dialog-title *ngIf="data.action === agreementActionType.EXTEND">
    Extend Expire date
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>

  <div mat-dialog-content class="dialogContentContainer">
    <div class="current-dates">
      <div *ngIf="data.effectiveDate">
        <h4>Current Effective Date</h4>
        <p>{{ data.effectiveDate | date: util.dateFormat }}</p>
      </div>
      <div *ngIf="data.expiryDate">
        <h4>Current Expiry Date</h4>
        <p>{{ data.expiryDate | date: util.dateFormat }}</p>
      </div>
    </div>
    <div class="form-container">
      <mat-form-field>
        <mat-label>New Expiration Date</mat-label>
        <input
          required
          matInput
          [min]="actionMinDate"
          [max]="actionMaxDate"
          [matDatepicker]="expirationDatePicker"
          [(ngModel)]="data.defaultDate"
          (ngModelChange)="onInputChange($event)"
        />
        <mat-error *ngIf="lessThanExpiryDate">Expire date cannot be lesser than location expiration date</mat-error>
        <mat-error *ngIf="moreThanExpiryDate">Expire date cannot be greater than location expiration date</mat-error>
        <mat-error *ngIf="lessThanEffectiveDate">Expire date cannot be lesser than location effective date</mat-error>

        <mat-error *ngIf="!data.defaultDate">Invalid date</mat-error>
        <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expirationDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <xpo-button-group>
      <button
        mat-flat-button
        xpoLargeButton
        xpoButton
        [disabled]="lessThanExpiryDate || moreThanExpiryDate || lessThanEffectiveDate || !data.defaultDate"
        [mat-dialog-close]="data.defaultDate"
      >
        Save
      </button>
    </xpo-button-group>
  </div>
</div>
