export const XPO_FILTER_TYPE_NAMES_MAP = {
  // Numbers
  equals: 'is',
  notEqual: 'is not',
  lessThan: 'is less than',
  lessThanOrEqual: 'is less than or equals',
  greaterThan: 'is greater than',
  greaterThanOrEqual: 'is greater than or equals',
  inRange: 'is between',
  contains: 'contains',
  notContains: 'not contains',
  startsWith: 'starts with',
  endsWith: 'ends with',
  empty: 'is empty',
  set: 'is',
};

export const XPO_FILTER_OPERATORS_MAP = {
  // Es-Lint disabled due to these are the name ag-grid uses, can't follow other naming convention here
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AND: 'and',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OR: 'or',
};

export const XPO_ARRAY_JOINER_TEXT: string = ', ';
export const XPO_IN_RANGE_CONNECTOR: string = 'and';
export const XPO_ENUM_TEXT_MAX_LENGTH: number = 25;
export const XPO_ENUM_EMPTY_TEXT: string = '(Empty)';

export const XPO_DATE_FILTER_PARSER = (filter: { [key: string]: any }): string => {
  function getOnlyDate(date: string): string {
    // remove timestamp and get only the date in YYYY-MM-DD
    // and the split by '-' to get each date part
    let dateParts = date.split(' ')[0].split('-');
    // reformat to MM/DD/YYYY for display consistency
    return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
  }
  const dateFrom = `${XPO_FILTER_TYPE_NAMES_MAP[filter.type]}: ${getOnlyDate(filter.dateFrom)}`;
  return filter.dateTo ? `${dateFrom} ${XPO_IN_RANGE_CONNECTOR} ${getOnlyDate(filter.dateTo)}` : dateFrom;
};

export const XPO_SET_FILTER_PARSER = (filter: { [key: string]: any }): string => {
  const finalText: string = `${XPO_FILTER_TYPE_NAMES_MAP[filter.filterType]} ${filter.values
    .join(XPO_ARRAY_JOINER_TEXT)
    .trim()}`;
  return finalText.length > XPO_ENUM_TEXT_MAX_LENGTH
    ? `${finalText.substring(0, XPO_ENUM_TEXT_MAX_LENGTH)}...`
    : finalText;
};

export const XPO_TEXT_FILTER_PARSER = (filter: { [key: string]: any }): string =>
  `${XPO_FILTER_TYPE_NAMES_MAP[filter.type]} ${filter.filter}`;

export const XPO_NUMBER_FILTER_PARSER = (filter: { [key: string]: any }): string => {
  const filterFrom = filter.filter;
  const filterTo = filter.filterTo;
  return `${XPO_FILTER_TYPE_NAMES_MAP[filter.type]} ${
    filterTo ? `${filterFrom} ${XPO_IN_RANGE_CONNECTOR} ${filterTo}` : filterFrom
  }`;
};

export const XPO_MULTI_FILTER_PARSER = (filter: { [key: string]: any }): string => {
  let finalText: string = '';
  filter.filterModels.forEach((model) => {
    if (!model) {
      return;
    }
    const cleanModel = [...model.values];
    // Ag-grid sets null in the first array element if user is using the "Blank" option to filter by blanks, so we need to display some text for it
    cleanModel.find((el, index) => {
      if (el === null) {
        cleanModel[index] = XPO_ENUM_EMPTY_TEXT;
      }
    });

    finalText = `${finalText} ${cleanModel.join(XPO_ARRAY_JOINER_TEXT)}`;
    finalText = `${XPO_FILTER_TYPE_NAMES_MAP[model.filterType]} ${finalText}`;
  });
  finalText = finalText.trim();
  finalText =
    finalText.length > XPO_ENUM_TEXT_MAX_LENGTH ? `${finalText.substring(0, XPO_ENUM_TEXT_MAX_LENGTH)}...` : finalText;
  return finalText;
};

export const XPO_PARSE_APPLIED_FILTER_TEXT = {
  number: XPO_NUMBER_FILTER_PARSER,
  text: XPO_TEXT_FILTER_PARSER,
  multi: XPO_MULTI_FILTER_PARSER,
  set: XPO_SET_FILTER_PARSER,
  date: XPO_DATE_FILTER_PARSER,
};
