import { Injectable } from '@angular/core';
import { Incentive, Program } from '@xpo-ltl/sdk-offbillincentive';
import { ProgramFields } from '../enums/agreement/program/program-fields.enum';
import { MetricFormat } from './grid/grid-column-partial-helper';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { FormControl } from '@angular/forms';
import { Util } from '../enums/util.enum';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    public snackbar: XpoSnackBar,
    private datePipe: DatePipe
  ) {}

  // Needed to fix some field values that contains extra whitespaces
  // that affect the applied filter 'is exact' match criteria
  removeExtraWhiteSpaces(fieldData: string) {
    return fieldData ? fieldData.replace(/\s\s+/g, ' ').trim() : null;
  }

  // Needed to match the filterParams object, as the provided
  // enum does not makes the filter to work properly
  enumToFilterParams(enumObject: any) {
    const filterParams = {};
    Object.entries(enumObject).forEach(([key, val]) => {
      filterParams[enumObject[key]] = val;
    });
    return filterParams;
  }

  enumToKeyValueArray(enumObject: any) {
    return Object.entries(enumObject).reduce((values, [currKey, currVal]) => {
      const obj = { key: currKey, value: currVal };
      return [obj, ...values];
    }, []);
  }

  cloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * @param seek  Object used to iterate over its properties.
   * @param match Object used to match values with seek property.
   * @param skip  List of properties of seek object that will be ignored.
   *
   * @returns `true` if objects are different otherwise `false`
   */
  compareObjects(seek: Object, match: Object, skip?: string[]): boolean {
    let changes: boolean;

    if (skip) {
      skip.forEach((prop: string) => {
        if (seek && seek[prop]) {
          delete seek[prop];
        }
      });
    }

    for (const [key, val] of Object.entries(seek)) {
      changes = match && match[key] !== val ? true : false;
      if (changes) {
        break;
      }
    }

    return changes;
  }

  formatProNumber(proNbr) {
    if (!proNbr) {
      return;
    }

    const isLongFormat = proNbr.charAt(0) === '0' && proNbr.charAt(4) === '0' ? true : false;

    return isLongFormat ? [proNbr.slice(1, 4), proNbr.slice(5)].join('') : proNbr;
  }

  setListOfProgramTypes(programs: Program[]): string {
    return programs?.reduce((list: string, program, index, arr) => {
      const programTypeCd = program.programTypeCd;
      return !(index === arr.length - 1) ? (list += programTypeCd + ', ') : (list += programTypeCd);
    }, '');
  }

  getProgramTypes(programs: Program[]): string[] {
    const programTypeList = programs?.map((item) => item.programTypeCd);
    return programTypeList ? programTypeList : [];
  }

  addCustomEncoding(value: string | any) {
    const encoding = [
      { char: '&', code: '[AMP]' },
      { char: '#', code: '[NUM]' },
      { char: '+', code: '[PLUS]' },
    ];

    if (encoding.some((entry) => value.includes(entry.char))) {
      for (const entry of encoding) {
        value = value.replaceAll(entry.char, entry.code);
      }
    }

    return value;
  }

  currentIncentive(incentives: Incentive[], field): any {
    if (!incentives.length) {
      return;
    }

    const sorted = incentives
      .sort((a, b) => (a.auditInfo.createdTimestamp as any) - (b.auditInfo.createdTimestamp as any))
      .reverse();
    const mostRecent = sorted[0];

    return mostRecent[field];
  }

  getError(data): string[] {
    let errorList: string[] = [];
    if (data && data.error && data.error.moreInfo) {
      data.error.moreInfo.forEach((element) => {
        const location = element.location;
        const field = this.getFieldName(location);

        let message: string = `${element.message}`;

        if (field) {
          message += ` at ${field} field`;
        }

        errorList.push(message);
      });
    } else {
      errorList.push('Unexpected error');
    }
    return errorList;
  }

  getSingleBulkProcessError(errorMsgs: string[]): string[] {
    let errorList: string[] = [];
    if (errorMsgs && errorMsgs.length > 0) {
      errorMsgs.forEach((errorMessage) => {
        errorList.push(errorMessage);
      });
    } else {
      errorList.push('Unexpected error');
    }
    return errorList;
  }

  getDialogTitle(errorList: string[]) {
    return errorList.length > 1 ? ' Error List' : ' Error';
  }

  showErrorSnackbar(error, actionMessage: string) {
    let errorList = this.getError(error);
    this.snackbar.open({
      message: actionMessage + this.getDialogTitle(errorList),
      detailedMessage: errorList.toString(),
      status: 'error',
      matConfig: {
        duration: 0,
      },
    });
  }

  getFieldName(location) {
    const field = location?.split('.')[1];
    return ProgramFields[field];
  }

  numberWithCommas(x): string {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
  }

  commentValidator(control: FormControl): { [key: string]: boolean } {
    //Allowed:
    // Alphanumeric characters: a-z, A-Z, 0-9
    // Special characters: $ = @ . , / ( ) & - +
    // Spaces and new lines.
    const commentRegExp: RegExp = /^[a-zA-Z0-9 $=@.,\/()%&+\n-]*$/;
    if (control.value && !commentRegExp.test(control.value)) {
      return { invalidComment: true };
    }
  }

  ///////////////// Format Date and Time ////////////////

  transformDate(date: any) {
    return new Date(this.datePipe.transform(date, Util.dateFormat));
  }

  getTimeStamp(date: any): string {
    return typeof date === 'object' ? moment(date).format('x') : date;
  }

  get lastPeriodDates() {
    const priorMonth = moment().subtract(1, 'months');
    return {
      begin: priorMonth.startOf('month').toDate(),
      end: priorMonth.endOf('month').toDate(),
    };
  }
  /////////////////////////////

  static formatValue(value: number, valueFormat?: MetricFormat): string {
    if (value !== undefined && value !== null) {
      let formatingValue: number = +value ?? 0;
      if (valueFormat) {
        switch (valueFormat) {
          case MetricFormat.Dollar:
            return `$   ${formatingValue
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          case MetricFormat.Dollar0:
            return `$   ${formatingValue
              .toFixed(0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          case MetricFormat.PercentDisplay:
            return formatingValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' %';
          case MetricFormat.Percent:
            return (formatingValue * 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' %';
          case MetricFormat.PercentFixed0:
            return (formatingValue * 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' %';
          case MetricFormat.Fixed0:
            return formatingValue
              .toFixed(0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          case MetricFormat.Fixed1:
            return formatingValue
              .toFixed(1)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          case MetricFormat.Fixed2:
            return formatingValue
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          case MetricFormat.Fixed3:
            return formatingValue
              .toFixed(3)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          case MetricFormat.Fixed4:
            return formatingValue
              .toFixed(4)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          default:
            return formatingValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      } else {
        return `${value}`;
      }
    }
    return '';
  }

  openCustomerInfo(customerInstId) {
    let cbaseInfoUrl = 'https://cust.ltl-xpo.com/location/' + customerInstId + '/customer/account-information';
    window.open(cbaseInfoUrl, '_blank');
  }
}
