import { Component, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { User } from '@xpo-ltl/sdk-common';
import { invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { interval } from 'rxjs';
import { delay, retryWhen, skipWhile, take, tap } from 'rxjs/operators';
import { NotificationMenuItem } from './models/notification-menu-item.model';
import { AppRoleService } from './services/user-role/app-role.service';
import { ObiApiService } from './services/incentive/obi-api.service';
import { AppActions } from './enums/app-actions.enum';
import { AppRoutes } from './enums/app-routes.enum';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';
import { AppMainMenu } from './enums/app-main-menu.enum';
import { Util } from './enums/util.enum';
import { Title } from '@angular/platform-browser';

interface XpoShellRouteSecure extends XpoShellRoute {
  permission?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  appVersion: string;
  allowedRoutes: XpoShellRoute[];
  menuItems = AppMainMenu;
  notifications: NotificationMenuItem[];
  notificationInterval;
  roleList: string;
  region: string = '';
  isProduction: boolean = false;
  readonly releaseNotesURL: string =
    'https://xpologistics.sharepoint.com/:f:/r/sites/150/projects/LTLOBI/Monitor%20and%20Control/Release%20Notes?csf=1&web=1&e=oy1LyM';
  readonly customerBaseURL: string = 'https://cust.ltl-xpo.com/customer-search';
  readonly shipmentManagementURL: string = 'https://shplist.ltl-xpo.com/home';
  readonly ratingURL: string = 'https://rating.ltl-xpo.com/rating-analysis';
  readonly lookerReportURL: string = 'https://xpologisticsprod.cloud.looker.com/looks/165';

  allRoutes: XpoShellRouteSecure[] = [
    {
      label: AppMainMenu.offBillIncentiveList,
      path: `/${AppRoutes.OFF_BILL_INCENTIVE}/${AppRoutes.LIST_PAGE}`,
      permission: AppActions.incentive_list,
    },
    {
      label: AppMainMenu.agreementList,
      path: `/${AppRoutes.OBI_AGREEMENT}/${AppRoutes.LIST_PAGE}`,
      permission: AppActions.agreement_list,
    },
    {
      label: AppMainMenu.newIncentive,
      path: `/${AppRoutes.OFF_BILL_INCENTIVE}/${AppRoutes.LIST_PAGE}/${AppRoutes.ADD}`,
      permission: AppActions.incentive_add,
    },
  ];

  constructor(
    private configManagerService: ConfigManagerService,
    private appRoleService: AppRoleService,
    private obiService: ObiApiService,
    private authService: XpoLtlAuthenticationService,
    private titleService: Title
  ) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    this.region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(this.region).subscribe((info: RegionInfo) => {});

    /** Shell setup */
    this.appVersion = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

    this.handleLoggedInUser();

    this.appRoleService.enabledActionObs.subscribe((resp) => {
      this.allowedRoutes = this.getUserRoutes(this.allRoutes);
    });
    if (!this.isProduction) this.titleService.setTitle(`OBI - ${this.region}`);
  }

  private getUserRoutes(routes: XpoShellRouteSecure[]): XpoShellRoute[] {
    return routes
      .filter((route: XpoShellRouteSecure) => {
        return this.appRoleService.getEnableActionByCode(route.permission);
      })
      .map((route: XpoShellRouteSecure) => {
        return {
          label: route.label,
          path: route.path,
        };
      });
  }

  private handleLoggedInUser(): void {
    this.obiService.offBillIncentiveApiService
      .loggedInUser()
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (user: User) => {
          if (user) {
            this.appRoleService.setUser(user);
            this.setDynatraceUserIdentity(user);
          }

          this.obiService.getNotifications();

          if (this.notificationInterval) {
            clearInterval(this.notificationInterval);
          }
          this.notificationInterval = setInterval(this.obiService.getNotifications, Util.timeout);
          this.appRoleService.getRoleName().subscribe((data) => {
            this.roleList = this.formatRoleList(data);
          });
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  private formatRoleList(roles: string[]) {
    return roles.reduce((acc, curr, index, list) => {
      return (acc += index === list.length - 1 ? curr : curr + ' - ');
    }, '');
  }

  populateAccountPopover(): boolean {
    return this.appRoleService.getEnableActionByCode(AppActions.incentive_add);
  }

  isMenuAllowed(menu: string): boolean {
    return this.allowedRoutes.some((route) => route.label === menu);
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(window['dtrum'], 'identifyUser', !_isEmpty(user.emailAddress) ? user.emailAddress : user.userId);
    if ((window['dtrum'] || {})['identifyUser']) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {})['identifyUser'] && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }
}
