export enum IncentiveStatusDisplayCd {
  PENDING = 'Pending',
  WAIT_CHECK_RQST = 'Approved - Waiting AP Request',
  WAIT_CHECK_RQST_CODE = 'WAIT_CHECK_RQST',
  UNDISTRIBUTED = 'Approved - AP File Generated',
  APPROVED = 'Approved',
  DENIED = 'Denied',
}

export enum IncentiveStatusCd {
  PENDING = 'PENDING',
  WAIT_CHECK_RQST = 'WAIT_CHECK_RQST',
  WAIT_CHECK_RQST_CODE = 'WAIT_CHECK_RQST',
  UNDISTRIBUTED = 'UNDISTRIBUTED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}
