import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationMenuItem } from '../models/notification-menu-item.model';
import { StateService } from './state/state-service';

interface NotificationState {
  refresh: boolean;
  notifications: NotificationMenuItem[];
}

const initialState: NotificationState = {
  refresh: false,
  notifications: [],
};

@Injectable({
  providedIn: 'root',
})
export class NotificationStateService extends StateService<NotificationState> {
  constructor() {
    super(initialState);
  }

  get notifications$(): Observable<NotificationMenuItem[]> {
    return this.select((state) => state.notifications);
  }
  refresh$: Observable<boolean> = this.select((state) => state.refresh);

  setNotifications(batchItems: NotificationMenuItem[]) {
    let hasChanged = false;

    if (this.state.notifications.length) {
      hasChanged = this.compareObjects(batchItems, this.state.notifications);
    }

    if (hasChanged) {
      this.pushGridUpdate(true);
    }

    this.pushNotifications(batchItems);
  }

  compareObjects(batch: NotificationMenuItem[], state: NotificationMenuItem[]) {
    const batchKeys = Object.keys(batch);
    const stateKeys = Object.keys(state);

    let lengthChanged = false;
    let canRetryChanged = false;

    if (batchKeys.length !== stateKeys.length) {
      lengthChanged = true;
    }

    stateKeys.some((key) => {
      if (batch[key] && batch[key].canRetry !== state[key].canRetry) {
        canRetryChanged = true;
      }
    });

    return lengthChanged || canRetryChanged;
  }

  pushNotifications(batchItems: NotificationMenuItem[]) {
    this.setState({
      notifications: batchItems,
    });
  }

  pushGridUpdate(value: boolean) {
    this.setState({ refresh: value });
  }
}
