import { Injectable } from '@angular/core';
import { AuditInfo } from '@xpo-ltl/sdk-common';
import {
  ListPricingCustomerDetailsQuery,
  ListPricingCustomerDetailsResp,
  PricingAdminPersonnel,
} from '@xpo-ltl/sdk-pricing';
import {
  ListPricingAnalystsQuery,
  ListPricingAnalystsResp,
  ListPricingCodesQuery,
  ListPricingCodesResp,
  PricingCode,
  PricingWorkbenchApiService,
} from '@xpo-ltl/sdk-pricingworkbench';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { PricingCodesCategories } from '../../enums/pricing-codes-categories-enum';
import { Util } from '../../enums/util.enum';

@Injectable({
  providedIn: 'root',
})
export class PricingWorkbenchService {
  customerQueryParams: ListPricingCustomerDetailsQuery = new ListPricingCustomerDetailsQuery();
  _locationQueryParams: any = {};
  customerList: Observable<ListPricingCustomerDetailsResp>;
  customerIdCache;
  pricingCodeListCache$;
  accountDetailCache$;
  constructor(private pricingWorkBench: PricingWorkbenchApiService) {}

  requestPricingCodes(categories: any, subcategories: any): Observable<PricingCode[]> {
    const queryParams = new ListPricingCodesQuery();
    queryParams.category = categories;
    queryParams.subCategory = subcategories;
    return this.pricingWorkBench.listPricingCodes(queryParams).pipe(
      map((response: ListPricingCodesResp) => {
        return response.pricingCodes;
      })
    );
  }

  requestObiPricingCodes(): Observable<PricingCode[]> {
    const categoryCodes = [PricingCodesCategories.obi];
    const subCategoryCodes = [PricingCodesCategories.security];

    if (!this.pricingCodeListCache$) {
      this.pricingCodeListCache$ = this.requestPricingCodes(categoryCodes, subCategoryCodes).pipe(shareReplay());
    }
    return this.pricingCodeListCache$;
  }

  getPricingCode(): Observable<PricingCode[]> {
    const queryParams = new ListPricingCodesQuery();
    queryParams.category = [PricingCodesCategories.obi];
    queryParams.subCategory = [Util.STATE_COMPONENT.toString()];

    return this.pricingWorkBench.listPricingCodes(queryParams).pipe(
      map((response: ListPricingCodesResp) => {
        return response.pricingCodes;
      })
    );
  }

  getPricingAnalystDetails(pricingAnalystEmplId: string): Observable<ListPricingAnalystsResp> {
    const queryParams = new ListPricingAnalystsQuery();
    queryParams.employeeId = pricingAnalystEmplId; //? `${pricingAnalystEmplId},OBIBATCH` : '';
    queryParams.levelOfDetail = 'Security';

    return this.pricingWorkBench.listPricingAnalysts(queryParams);
  }

  getPricingAnalystDetailsFromAuditInfo(params, item: AuditInfo) {
    const defaultAnalyst = new PricingAdminPersonnel(); // Find same model in pricing workbench sk
    defaultAnalyst.employeeId = params.updateById || params.employeeFullName;

    return this.pricingWorkBench
      .listPricingAnalysts(params, {
        toastOnError: false,
        loadingOverlayEnabled: true,
      })
      .pipe(
        map((analyst) => ({
          ...analyst.pricingAnalysts[0],
          ...{ timestamp: item.updatedTimestamp, error: false },
        })),
        catchError((_err) => {
          return of({ ...defaultAnalyst, ...{ timestamp: item.updatedTimestamp, error: true } });
        })
      );
  }
}
