import { Injectable } from '@angular/core';

export enum ResultGridType {
  shipments,
  locations,
}
@Injectable({ providedIn: 'root' })
export class ContextGridService {
  private gridType: ResultGridType = undefined;

  setGridType(type: ResultGridType): void {
    this.gridType = type;
  }

  getGridType(): ResultGridType {
    return this.gridType;
  }
}
