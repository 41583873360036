<div xpoDialog>
  <h1 mat-dialog-title>
    Results
    <xpo-dialog-close-button (click)="cancel()"></xpo-dialog-close-button>
  </h1>
  <div mat-dialog-content class="dialog-container">
    <app-bulk-result></app-bulk-result>
  </div>

  <div mat-dialog-actions>
    <button mat-stroked-button xpoButton (click)="cancel()">Close</button>
  </div>
</div>
