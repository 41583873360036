import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { GridColumnPartialsHelperService } from '../../../../services/grid/grid-column-partial-helper';

@Component({
  selector: 'app-grid-skeleton',
  templateUrl: './grid-skeleton.component.html',
  styleUrls: ['./grid-skeleton.component.scss'],
})
export class GridSkeletonComponent implements OnInit {
  gridApi: GridApi;
  columnApi: ColumnApi;

  private rowData: any[] = undefined;

  private columDefs: ColDef[] = undefined;

  gridOptions: GridOptions = {
    defaultColDef: {
      ...GridColumnPartialsHelperService.DefaultColumnDefinition,
    },
    ...GridColumnPartialsHelperService.DefaultGridOptions,
    rowData: this.rowData,
    columnDefs: this.columDefs,
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }
}
