<div class="result-list-grid">
  <h3>Results</h3>
  <app-grid-applied-filters [gridApi]="gridApi"></app-grid-applied-filters>

  <xpo-ag-grid>
    <xpo-grid-toolbar>
      <xpo-grid-toolbar-settings-group>
        <button
          mat-icon-button
          (click)="downloadExcel('result-list')"
          matTooltip="Download as Excel"
          matTooltipPosition="left"
          [disabled]="!enableDownload"
        >
          <xpo-icon iconName="download" xpoIconLarge></xpo-icon>
        </button>
      </xpo-grid-toolbar-settings-group>

      <xpo-grid-toolbar-toggle-buttons-group>
        <mat-button-toggle-group xpoSmallButton (change)="onResultListViewChange($event)">
          <mat-button-toggle value="all" checked="true"> All Results </mat-button-toggle>
          <mat-button-toggle value="error"> Error </mat-button-toggle>
          <mat-button-toggle value="success"> Success </mat-button-toggle>
        </mat-button-toggle-group>
      </xpo-grid-toolbar-toggle-buttons-group>
    </xpo-grid-toolbar>

    <ag-grid-angular
      class="grid-container"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
  </xpo-ag-grid>
</div>
