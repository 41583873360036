import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '@xpo-ltl/data-api';
import {
  Column,
  ColumnApi,
  ColDef,
  ColumnMovedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
} from 'ag-grid-community';
import { FileExporterService } from '../../../services/file-exporter.service';
import { Util } from '../../../enums/util.enum';
import { ContextGridService, ResultGridType } from '../../../services/grid/context-grid';
import { AgreementListService } from '../../../services/agreement/agreement-list.service';
import { GridColumnPartialsHelperService } from '../../../services/grid/grid-column-partial-helper';
import { GridAppliedFiltersComponent } from '../grid/grid-applied-filters/grid-applied-filters.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

enum ResultViews {
  all = 'all',
  error = 'error',
  success = 'success',
}

@Component({
  selector: 'app-bulk-result',
  templateUrl: './bulk-result.component.html',
  styleUrls: ['./bulk-result.component.scss'],
})
export class BulkResultComponent implements OnInit, OnDestroy {
  @ViewChild(GridAppliedFiltersComponent) appliedFilter: GridAppliedFiltersComponent;

  gridApi: GridApi;
  columnApi: ColumnApi;
  excelData: String | Blob;
  enableDownload: boolean = false;
  currentView: string = ResultViews.all;

  gridType: ResultGridType;
  results;

  gridOptions: GridOptions = {
    defaultColDef: {
      ...GridColumnPartialsHelperService.DefaultColumnDefinition,
    },
    ...GridColumnPartialsHelperService.DefaultGridOptions,

    onColumnMoved: (event: ColumnMovedEvent) => {
      this.gridApi.setColumnDefs(event.api.getColumnDefs());
    },
    columnDefs: this.getResultColumns(),
    isExternalFilterPresent: () => this.isExternalFilterPresent(),
    doesExternalFilterPass: (data) => this.doesExternalFilterPass(data),
    onFilterChanged: (event) => {
      this.appliedFilter.updateAppliedFilters();
    },
  };

  constructor(
    private agreementListService: AgreementListService,
    private contextGridService: ContextGridService,
    private notificationService: NotificationService,
    private fileExporter: FileExporterService
  ) {}

  ngOnInit(): void {
    this.gridType = this.contextGridService.getGridType();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.getResultsData();
  }

  getResultsData() {
    this.agreementListService.mockBulkResults().subscribe((results) => {
      this.enableDownload = results.length > 0;
      this.gridApi.setRowData(results);
    });
  }

  isExternalFilterPresent(): boolean {
    return this.currentView === ResultViews.error || this.currentView === ResultViews.success;
  }

  doesExternalFilterPass(node: RowNode): boolean {
    if (node.data) {
      switch (this.currentView) {
        case ResultViews.error:
          return (
            node.data.bulkProcessStatus === 400 ||
            node.data.bulkProcessStatus === 404 ||
            node.data.bulkProcessStatus === 500
          );
        case ResultViews.success:
          return node.data.bulkProcessStatus === 200 || node.data.bulkProcessStatus === 201;
        default:
          return true;
      }
    }
    return true;
  }

  onResultListViewChange(event: MatButtonToggleChange) {
    this.currentView = event.value;
    this.gridApi.onFilterChanged();
  }

  private getResultColumns(): ColDef[] {
    let contextualColumns: ColDef[] = [];
    this.gridType = this.contextGridService.getGridType();
    switch (this.gridType) {
      case ResultGridType.shipments:
        contextualColumns = [
          {
            headerName: 'PRO Number',
            field: 'bulkProNbr',
            width: 120,
            resizable: true,
            filter: 'agTextColumnFilter',
          },
        ];
        break;
      case ResultGridType.locations:
        contextualColumns = [
          {
            headerName: 'MAD Code',
            field: 'bulkMadCode',
            width: 120,
            resizable: true,
            filter: 'agTextColumnFilter',
          },
        ];
        break;
    }

    return [
      GridColumnPartialsHelperService.RowIndex,
      {
        headerName: 'Id',
        field: 'bulkProcessId',
        width: 150,
        filter: 'agTextColumnFilter',
        valueFormatter: (params) => (params.value?.includes(Util.notFound) ? Util.notFound : params.value),
      },
      ...contextualColumns,
      {
        headerName: 'Status',
        field: 'bulkProcessStatus',
        maxWidth: 200,
        resizable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => this.getStatus(params.data.bulkProcessStatus),
      },
      {
        headerName: 'Description',
        field: 'bulkProcessMessages',
        resizable: true,
        minWidth: 400,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        wrapText: true,
      },
    ];
  }

  getStatus(status: number): string {
    switch (status) {
      case 200:
        return Util.ok;
      case 201:
        return Util.created;
      case 500:
        return Util.serverError;
      case 404:
        return Util.notFound;
      default:
        return Util.validated;
    }
  }

  downloadExcel(name) {
    this.notificationService.showOverlayMessage();

    if (this.columnApi) {
      const columns: Column[] = this.columnApi.getAllColumns();
      this.excelData = this.buildExcelData(columns);
    }

    this.fileExporter.setMimeType('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    this.fileExporter.setData(this.excelData);

    this.fileExporter.download(name).subscribe({
      next: (file) => this.fileExporter.save(file.name),
      complete: () => this.notificationService.hideOverlayMessage(),
    });
  }

  buildExcelData(columns: Column[]): string | Blob {
    if (!this.gridApi) {
      return;
    }

    if (this.gridApi.getModel().getRowCount() > 0) {
      return this.gridApi.getDataAsExcel({
        columnKeys: columns,
      });
    }
  }

  ngOnDestroy(): void {
    this.contextGridService.setGridType(undefined);
  }
}
