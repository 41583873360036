<div xpoDialog>
  <h1 mat-dialog-title>
    Backdate Effective Date
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div mat-dialog-content class="dialogContentContainer">
    <div class="current-dates">
      <div>
        <h4>Current Effective Date</h4>
        <p>{{ data.effectiveDate | date: util.dateFormat }}</p>
      </div>
      <div>
        <h4>Current Expiry Date</h4>
        <p>{{ data.expiryDate | date: util.dateFormat }}</p>
      </div>
    </div>
    <div class="form-container">
      <mat-form-field>
        <mat-label>New Effective Date</mat-label>
        <input
          matInput
          required
          [matDatepicker]="backdateDatePicker"
          [min]="beginDate"
          [max]="data.effectiveDate"
          [(ngModel)]="data.defaultDate"
          (ngModelChange)="onInputChange($event)"
        />
        <mat-error *ngIf="!data.defaultDate">Invalid date</mat-error>
        <mat-error *ngIf="moreThanEffectiveDate">Backdate cannot be greater than location effective date</mat-error>
        <mat-error *ngIf="lessThanBeginDate"
          >Backdate cannot be more than 2 years in the past from today's date</mat-error
        >
        <mat-datepicker-toggle matSuffix [for]="backdateDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #backdateDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <xpo-button-group>
      <button
        mat-flat-button
        xpoLargeButton
        xpoButton
        [disabled]="moreThanEffectiveDate || !data.defaultDate"
        [mat-dialog-close]="data.defaultDate"
      >
        Save
      </button>
    </xpo-button-group>
  </div>
</div>
