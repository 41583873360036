import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { AgreementActionType } from '../../../enums/agreement/agreementActionType.enum';
import { Util } from '../../../enums/util.enum';

@Component({
  selector: 'app-expire-dialog',
  templateUrl: './expire-dialog.component.html',
  styleUrls: ['./expire-dialog.component.scss'],
})
export class ExpireDialogComponent implements OnInit {
  actionMinDate: number;
  actionMaxDate: number;
  lessThanEffectiveDate: boolean = false;
  lessThanExpiryDate: boolean = false;
  moreThanExpiryDate: boolean = false;

  agreementActionType = AgreementActionType;
  readonly util = Util;
  constructor(
    public dialogRef: MatDialogRef<ExpireDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    switch (this.data.action) {
      case AgreementActionType.EXPIRY:
        this.actionMinDate = this.data.effectiveDate;
        this.actionMaxDate = this.data.expiryDate;
        break;
      case AgreementActionType.EXTEND:
        this.actionMinDate = this.data.expiryDate;
        break;
    }
    this.validateInput(new Date(moment(this.data.defaultDate).format('MM/DD/YYYY')).getTime());
  }

  onInputChange(event) {
    const userDate = new Date(event).getTime();
    this.validateInput(userDate);
  }

  validateInput(inputDate: number) {
    switch (this.data.action) {
      case AgreementActionType.EXPIRY:
        this.lessThanEffectiveDate = this.data.effectiveDate
          ? inputDate < new Date(moment(this.data.effectiveDate).format('MM/DD/YYYY')).getTime()
          : false;
        this.moreThanExpiryDate = this.data.expiryDate
          ? inputDate > new Date(moment(this.data.expiryDate).format('MM/DD/YYYY')).getTime() ||
            inputDate === new Date(moment(this.data.expiryDate).format('MM/DD/YYYY')).getTime()
          : false;
        break;
      case AgreementActionType.EXTEND:
        this.lessThanEffectiveDate = this.data.effectiveDate
          ? inputDate < new Date(moment(this.data.effectiveDate).format('MM/DD/YYYY')).getTime()
          : false;
        this.lessThanExpiryDate = this.data.expiryDate
          ? inputDate < new Date(moment(this.data.expiryDate).format('MM/DD/YYYY')).getTime() ||
            inputDate === new Date(moment(this.data.expiryDate).format('MM/DD/YYYY')).getTime()
          : false;
        break;
    }
  }
}
