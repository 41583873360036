import { ColDef, GridOptions } from 'ag-grid-community';
import moment from 'moment';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ag-grid';
import { HelperService } from '../helper.service';
import { CurrencyCellRendererComponent } from '../../shared/cell-renderers/currency-cell-renderer/currency-cell-renderer.component';
import { BooleanCellRendererComponent } from '../../shared/cell-renderers/boolean-cell-renderer/boolean-cell-renderer.component';
import { Util } from '../../enums/util.enum';
import { InputEnum } from '../../enums/input.enum';

export enum MetricFormat {
  Dollar = 'Dollar',
  Dollar0 = 'Dollar0',
  PercentDisplay = 'PercentDisplay',
  Percent = 'Percent',
  PercentFixed0 = 'PercentFixed0',
  Fixed0 = 'Fixed0',
  Fixed1 = 'Fixed1',
  Fixed2 = 'Fixed2',
  Fixed3 = 'Fixed3',
  Fixed4 = 'Fixed4',
}

export class GridColumnPartialsHelperService {
  static readonly ValueFormatterPercentDisplay: Partial<ColDef> = {
    valueFormatter: (param) => HelperService.formatValue(param.value, MetricFormat.PercentDisplay),
    filterValueGetter: (param) => param.getValue(param.colDef.field),
  };

  static readonly numberFormatter: Partial<ColDef> = {
    valueFormatter: (param) => Math.floor(param.value).toLocaleString(),
  };

  static readonly ValueFormatterPercent: Partial<ColDef> = {
    valueFormatter: (param) => HelperService.formatValue(param.value, MetricFormat.Percent),
    filterValueGetter: (param) => param.getValue(param.colDef.field) * 100,
  };

  static readonly ValueFormatterPercentFixed0: Partial<ColDef> = {
    valueFormatter: (param) => HelperService.formatValue(param.value, MetricFormat.PercentFixed0),
    filterValueGetter: (param) => param.getValue(param.colDef.field) * 100,
  };

  static readonly ValueFormatterFixed0: Partial<ColDef> = {
    valueFormatter: (param) => HelperService.formatValue(param.value, MetricFormat.Fixed0),
  };

  static readonly ValueFormatterFixed2: Partial<ColDef> = {
    valueFormatter: (param) => HelperService.formatValue(param.value, MetricFormat.Fixed2),
  };

  static readonly ValueFormatterValueOrDash: Partial<ColDef> = {
    valueFormatter: (param) => param.value ?? '-',
  };

  static readonly ValueGetterXpoOrHssLane: Partial<ColDef> = {
    valueGetter: (param) => {
      return param.data.lane.xpoLaneInd ? 'XPO' : 'HSS';
    },
  };

  static readonly Editable: Partial<ColDef> = {
    editable: true,
    cellClass: (params) => (params.colDef.editable ? 'grid-cell-editable' : ''),
  };

  static readonly AlignRight: Partial<ColDef> = {
    cellClass: () => 'grid-cell-align-right',
  };

  static ContentAlignCenter: Partial<ColDef> = {
    cellStyle: { placeItems: 'center', placeContent: 'end', display: 'inline-flex' },
  };

  static DollarFormat: Partial<ColDef> = {
    cellRendererFramework: CurrencyCellRendererComponent,
  };

  static MetricChange: Partial<ColDef> = {
    cellClassRules: {
      'metric-change--error': (params) => params.value < 0,
      'metric-change--info': (params) => params.value >= 0,
    },
  };

  static Status: Partial<ColDef> = {
    cellRenderer: (params) =>
      `<xpo-status-indicator [statusText]="${params.value.statusText}" [statusColor]="${params.value.statusColor}" [isLabel]="${params.value.isLabel}" [matTooltip]="tooltipText" [ngClass]="{'statusIndicatorAlignment': true, 'nonLabelStatusInd' : !isLabel, 'labelStatusInd' : isLabel}"></xpo-status-indicator>`,
  };

  static readonly XpoCustomBase: ColDef = {
    cellStyle: { padding: 0 },
    pinned: 'left',
    lockPosition: true,
    sortable: false,
    suppressMenu: true,
    filter: false,
    editable: false,
    resizable: false,
    suppressSizeToFit: true,
    suppressAutoSize: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    minWidth: 0,
    flex: 0,
    cellClass: 'xpo-AgGrid-rowIndexColumn',
    headerClass: 'xpo-AgGrid-rowIndexColumn',
  };

  static readonly XpoStatusColDef: ColDef = {
    ...GridColumnPartialsHelperService.XpoCustomBase,
    width: 30,
    minWidth: 30,
    maxWidth: 30,
    cellStyle: { 'justify-content': 'center' },
  };

  static readonly XpoActionButtonColDef: ColDef = {
    ...GridColumnPartialsHelperService.XpoCustomBase,
    width: 38,
    minWidth: 38,
    maxWidth: 38,
    cellStyle: { 'justify-content': 'center' },
  };

  static readonly RowIndex: ColDef = {
    ...XpoAgGridColumns.RowIndex,
    ...GridColumnPartialsHelperService.XpoCustomBase,
    cellStyle: { 'justify-content': 'center', 'font-weight': 'bold' },
    cellRenderer: (params) => {
      return params.node.rowPinned ? undefined : params.value;
    },
  };

  static readonly Selection: ColDef = {
    ...XpoAgGridColumns.Selection,
    ...GridColumnPartialsHelperService.XpoStatusColDef,
  };

  static readonly SelectionWithSelectAll: ColDef = {
    ...XpoAgGridColumns.SelectionWithSelectAll,
    ...GridColumnPartialsHelperService.XpoStatusColDef,
    headerCheckboxSelectionFilteredOnly: true,
  };

  static readonly DefaultColumnDefinition: ColDef = {
    resizable: true,
    sortable: true,
    menuTabs: ['filterMenuTab'],
    icons: {
      menu: `<mat-icon class="material-icons" style="font-size: 20px;">filter_list</mat-icon>`,
    },
  };

  static readonly DefaultGridOptions: GridOptions = {
    getContextMenuItems: (params) => {
      return ['copy', 'copyWithHeaders', 'excelExport'];
    },
    pagination: true,
    paginationPageSize: +Util.uiPageSize,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    enableRangeSelection: true,
    suppressCopyRowsToClipboard: true,
  };

  static readonly defaultDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('/');
      var cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1])); // MM/DD/YYYY format in the grid

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
  };

  static readonly defaultTextFilterParams = {
    filterOptions: ['equals'],
    defaultJoinOperator: 'OR',
  };

  static readonly defaultNumberFilterParams = {
    filterOptions: ['equals', 'lessThan', 'greaterThan'],
  };

  static dateSortComparator(date1, date2, isAscending = true) {
    var date1Number = GridColumnPartialsHelperService._monthToNum(date1);
    var date2Number = GridColumnPartialsHelperService._monthToNum(date2);

    // Handle null values
    if (date1Number === null && date2Number === null) {
      return 0; // both are null, treat as equal
    }
    if (date1Number === null) {
      return isAscending ? 1 : -1; // null at bottom for ascending, top for descending
    }
    if (date2Number === null) {
      return isAscending ? -1 : 1; // null at bottom for ascending, top for descending
    }

    return date1Number - date2Number;
  }

  // HELPER FOR DATE COMPARISON
  private static _monthToNum(date) {
    if (date === undefined || date === null || date.length !== 10) {
      return null;
    }

    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(0, 2);
    var dayNumber = date.substring(3, 5);

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    // Ex: 09/07/2024 = 2024090007
    return result;
  }

  /** Helper function to resolve nested fields */
  private static resolveNestedField(obj: any, field: string): any {
    return field.split('.').reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : null), obj);
  }

  /**  Handles creation and filtering of a Boolean Cell Renderer */
  static BooleanCell(): Partial<ColDef> {
    return {
      cellRendererFramework: BooleanCellRendererComponent,
      filter: 'agSetColumnFilter',
      flex: 0,
      valueGetter: (params) => {
        return GridColumnPartialsHelperService.resolveNestedField(params.data, params.colDef.field) ? 'True' : 'False';
      },
    };
  }

  /**  Handles display and filtering of a Date Cell Renderer. Pass in format to override default .
   *
   * Default Format: `"MM/DD/YY"`
   *
   * Example DateTime format: `"MM/DD/YYYY hh:mm:ss A"`
   *
   * */
  static DateCell(format?: string): Partial<ColDef> {
    return {
      filter: 'agDateColumnFilter',
      valueFormatter: (param) => {
        if (!param.value) return '-';
        const date = moment(param.value);
        return date.isValid() ? date.format(format ? format : 'MM/DD/YY') : param.value;
      },
      valueGetter: (params) => {
        return GridColumnPartialsHelperService.resolveNestedField(params.data, params.colDef.field)
          ? new Date(GridColumnPartialsHelperService.resolveNestedField(params.data, params.colDef.field))
          : null;
      },
    };
  }

  /**
   * Combines the cellClass definitions from multiple Partial<ColDef> objects.
   * @param colDefs Array of Partial<ColDef> objects.
   * @returns A single cellClass function that combines all definitions.
   */
  static combineCellClasses(...colDefs: Partial<ColDef>[]): (params: any) => string {
    return (params: any) => {
      let combinedClasses = [];

      for (const colDef of colDefs) {
        const cellClass = colDef.cellClass;
        if (typeof cellClass === 'function') {
          const result = cellClass(params);
          combinedClasses.push(...(Array.isArray(result) ? result : [result]));
        } else if (cellClass) {
          combinedClasses.push(...(Array.isArray(cellClass) ? cellClass : [cellClass]));
        }
      }

      return combinedClasses.filter(Boolean).join(' ');
    };
  }

  static getNumericCellEditor(): any {
    function isCharNumeric(charStr) {
      return !!/\d|\./.test(charStr);
    }
    function isKeyPressedNumeric(event) {
      const charCode = getCharCodeFromEvent(event);
      const charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === Util.undefined ? event.keyCode : event.which;
    }
    function isValueDecimal(value) {
      if (!/(?<=^| )\d+(\.\d+)?(?=$| )/.test(value) && value !== '') {
        value = isValueDecimal(value.substr(0, value.length - 1));
      }
      if (+value >= 10000) {
        return 9999.99;
      }
      return value;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function (params) {
      this.eFieldWrap = document.createElement('div');
      this.eFieldWrap.classList.add('mat-form-field-flex', 'custom-cell-editor');
      if (params.value !== '' || params.value !== undefined || params.value !== null) {
        this.focusAfterAttached = params.cellStartedEdit;

        this.eInputWrap = document.createElement('div');
        this.eInputWrap.classList.add('mat-form-field-infix');
        this.eInput = document.createElement(InputEnum.input);
        this.eInput.classList.add('mat-input-element');
        this.eInput.style.width = InputEnum.maxWidth;
        this.eInput.style.height = InputEnum.maxHeight;
        this.eInput.value = params.value;
        this.eInputWrap.append(this.eInput);

        this.eButtonWrap = document.createElement('div');
        this.eButtonWrap.classList.add('mat-form-field-suffix');
        this.eButton = document.createElement('mat-button');
        this.eButton.classList.add('mat-button', 'mat-icon-button', 'mat-button-base', 'editor-close');

        this.eIcon = document.createElement('mat-icon');
        this.eIcon.classList.add('mat-icon', 'material-icons', 'mat-icon-no-color');
        this.eIcon.setAttribute('role', 'img');
        this.eIcon.setAttribute('data-mat-icon-type', 'font');
        this.eIcon.textContent = 'close';
        this.eButton.append(this.eIcon);
        this.eButtonWrap.append(this.eButton);

        this.eFieldWrap.append(this.eInputWrap);
        this.eFieldWrap.append(this.eButtonWrap);

        const that = this;
        this.eInput.addEventListener(InputEnum.keypress, function (event) {
          if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) {
              event.preventDefault();
            }
          }
        });
        this.eInput.addEventListener(InputEnum.blur, function (event) {
          this.value = isValueDecimal(this.value);
        });
        this.eButton.addEventListener('click', function (event) {
          that.eInput.value = params.value;
          params.stopEditing(true);
        });
      }
    };
    NumericCellEditor.prototype.getGui = function () {
      return this.eFieldWrap;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () {};
    NumericCellEditor.prototype.getValue = function () {
      return isValueDecimal(this.eInput.value);
    };
    NumericCellEditor.prototype.focusIn = function () {
      const eInput = this.getGui();
      eInput.focus();
      eInput.select();
    };
    NumericCellEditor.prototype.focusOut = function () {
      const eInput = this.getGui();
      if (!isValueDecimal(eInput.value)) {
        eInput.focus();
      }
    };
    return NumericCellEditor;
  }
}
