import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { Util } from '../../../enums/util.enum';

@Component({
  selector: 'app-backdate-dialog',
  templateUrl: './backdate-dialog.component.html',
  styleUrls: ['./backdate-dialog.component.scss'],
})
export class BackdateDialogComponent implements OnInit {
  beginDate: Date = new Date();
  moreThanEffectiveDate: boolean = false;
  lessThanBeginDate: boolean = false;
  readonly util = Util;

  constructor(
    public dialogRef: MatDialogRef<BackdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.beginDate.setFullYear(this.beginDate.getFullYear() - 2);
    this.validateInput(new Date(moment(this.data.defaultDate).format('MM/DD/YYYY')).getTime());
  }

  onInputChange(event) {
    const userDate = new Date(event).getTime();
    this.validateInput(userDate);
  }

  validateInput(inputDate: number) {
    this.moreThanEffectiveDate =
      inputDate > new Date(moment(this.data.effectiveDate).format('MM/DD/YYYY')).getTime() ||
      inputDate === new Date(moment(this.data.effectiveDate).format('MM/DD/YYYY')).getTime();

    this.lessThanBeginDate = inputDate < new Date(moment(this.beginDate).format('MM/DD/YYYY')).getTime();
  }
}
