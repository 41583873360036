import { Component } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Program } from '@xpo-ltl/sdk-offbillincentive';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AppRoutes } from '../../../enums/app-routes.enum';

export interface GridProgramsCellRendererParams extends ICellRendererParams {
  programs: Program[];
  agreementId: string;
}

interface LinkableProgram {
  name: string;
  url?: UrlTree;
}
@Component({
  selector: 'app-obi-incentive-programs-cell-renderer',
  templateUrl: './grid-programs-cell-renderer.component.html',
  styleUrls: ['./grid-programs-cell-renderer.component.scss'],
})
export class GridProgramsCellRendererComponent implements ICellRendererAngularComp {
  programs: LinkableProgram[];
  agreementId: string;

  constructor(private router: Router) {}

  agInit(params: GridProgramsCellRendererParams) {
    if (params.programs) this.programs = this.renderPrograms(params.programs, params.agreementId);
  }

  renderPrograms(programs: Program[], agreementId?): LinkableProgram[] {
    return programs.map((value: any) => {
      const programId = value.obiProgramId;
      return {
        name: value.programTypeCd,
        url: this.getProgramUrl(agreementId, programId),
      };
    });
  }

  getProgramUrl(agreementId?, programId?) {
    return !!agreementId && !!programId
      ? this.router.createUrlTree([
          AppRoutes.OBI_AGREEMENT,
          AppRoutes.PROGRAM_DETAILS,
          agreementId,
          programId,
          AppRoutes.PROGRAM_HDR,
        ])
      : undefined;
  }

  redirect(programUrl) {
    window.open(programUrl, '_blank');
  }

  refresh(): boolean {
    return false;
  }
}
